















































































































import Vue from 'vue'
import { Product, ApiResponse, Rate } from '@/interfaces'
import { mapState } from 'vuex'
import { ElForm } from 'element-ui/types/form'

export default Vue.extend({
  props: {
    showFormModal: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object as () => Product,
      required: false,
      default: () => {
        return {
          structureId: null,
          active: true as boolean | null,
          tvaRate: '20' as string | null,
        }
      },
    },
    structure: {
      type: Number,
      required: false,
      default: null,
    },
    fetchProductId: {
      type: String,
      required: false,
      default: null,
    },
    isDisabled: Boolean,
  },
  data() {
    return {
      busy: this.isDisabled,
      productData: {
        structureId: null,
        active: true as boolean,
        opaque: false as boolean,
        defaultFor: null as string | null,
        tvaRate: '20' as string | null,
      } as Product,
      userStructure: null as number | null,
      structures: [],
      vatRates: [] as Rate[],
      formModel: {},
      formRules: {},
      viewportWidth: 0,
      displayTooltip: false,
      tooltipMessage: '',
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    showFormModal() {
      // This is used as a trick to reset the form data every time the form modal is shown,
      // as well as for the cancel button, so that it doesn't automatically redirect the user to the product list
      this.resetFormData()
    },
    user(newVal) {
      this.addons = newVal.client?.addons ?? 0
      this.structures = newVal.client.structures
      if (this.productData.structureId === null) {
        this.productData.structureId = newVal.client.structures[0]?.id ?? null
        this.vatRates = newVal.client.structures[0]?.taxRate?.rates ?? []
      }
    },
    fetchProductId(newVal) {
      if (Number.isInteger(parseInt(newVal))) {
        this.getProduct(newVal)
      } else {
        this.resetFormData()
      }
    },
  },
  mounted() {
    if (this.user) {
      this.structures = this.user.client.structures
      this.vatRates = this.user.client.structures[0]?.taxRate?.rates ?? []
    }

    if (Number.isInteger(parseInt(this.fetchProductId))) {
      this.getProduct(this.fetchProductId)
    } else {
      this.resetFormData()
    }

    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)

    this.addons = this.user.client?.addons ?? 0
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    resetFormData() {
      this.productData = {
        structureId: null,
        active: true,
        opaque: false,
        tvaRate: '20',
      }
    },
    formatFloat(value = ''): string {
      return value.replace(',', '.')
    },
    cancelForm() {
      if (this.showFormModal) {
        this.$emit('closeModal')
      } else {
        this.$router.push({ name: 'products' })
      }
    },
    getProduct(productId: string) {
      this.busy = true
      this.$api
        .get(`/product/${productId}`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.productData = apiResponse.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
    reset() {
      ; (this.$refs.myForm as ElForm).resetFields()
    },
    submit() {
      this.tooltipMessage = ''
      this.displayTooltip = false

      // Checking if at least the product name (or company name) is entered
      if (this.productData.name == '' || this.productData.name == null) {
        this.tooltipMessage = 'Merci de préciser au moins un nom de produit.'
        this.displayTooltip = true

        return false
      }

      // Checking structure
      if (this.productData.structureId == null) {
        this.tooltipMessage = 'Merci de choisir une structure.'
        this.displayTooltip = true

        return false
      }

      this.busy = true
        ; (this.$refs.myForm as ElForm).validate((valid: boolean) => {
          if (valid) {
            // Convert int value to string
            Object.keys(this.productData).map((key) => {
              if (key === 'unitPriceExcludingTaxes' || key === 'tvaRate') {
                this.productData[key] = this.formatFloat(this.productData[key]?.toString())
              }
            })

            delete this.productData.structure

            const data = {
              ...this.productData,
            }

            if (!this.productData.id) {
              // Store
              this.$api
                .post('/product', data)
                .then((response) => {
                  const apiResponse = response.data as ApiResponse

                  this.productData = apiResponse.data
                  this.$notify({
                    type: 'success',
                    title: 'Succès',
                    message: 'Produit créé avec succès !',
                  })
                  this.$emit('productCreated', apiResponse.data)
                })
                .catch((error) => {
                  if (error.response) {
                    const apiResponse = error.response.data as ApiResponse

                    this.$notify({
                      type: 'error',
                      title: 'Erreur',
                      message:
                        apiResponse.message ??
                        'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                    })
                  }
                })
                .finally(() => {
                  this.busy = false
                })
            } else {
              // Edit
              const loading = this.$loading({
                target: '.form-panel',
                text: 'Chargement des données...',
              })
              this.$api
                .put(`/product/${this.productData.id}`, data)
                .then((response) => {
                  const apiResponse = response.data as ApiResponse

                  this.productData = apiResponse.data
                  this.$notify({
                    type: 'success',
                    title: 'Succès',
                    message: 'Produit modifié avec succès !',
                  })
                  this.$emit('productUpdated', apiResponse.data)
                })
                .catch((error) => {
                  if (error.response) {
                    const apiResponse = error.response.data as ApiResponse

                    this.$notify({
                      type: 'error',
                      title: 'Erreur',
                      message:
                        apiResponse.message ??
                        'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                    })
                  }
                })
                .finally(() => {
                  this.busy = false
                  loading.close()
                })
            }
          }
          this.busy = false
        })
    },
  },
})
